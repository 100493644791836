body {
  --number-box-height: 45px;
  --number-box-width: 60px;
  --spacer-width: 15px;
  --number-box-background-color: #f1f1f4;
  --number-box-hover-background-color: #fffbd1;
  --matrix-cell-background-color: #e4e4f6;
  --action-box-background-color: #f1f1f480;
  --action-box-hover-background-color: #d5d5dd80;
  --action-box-hover-font-color: #0d0df280;
  --action-box-font-color: #2424a880;
  --action-box-border-color: #6c6ce080;
  --bubble-hover-background-color: #00f;
  --matrix-header-field: #ebebfa;
  --number-box-hover-font-color: #00f;
  --number-box-font-color: #0f0fbd;
  --number-box-border-color: #5a5af2;
  --bubble-hover-border-color: #ccf;
  --number-box-border-radius: 8px;
  --number-box-disabled-background-color: #e4e4e7;
  --number-box-disabled-font-color: #4d4d80;
  --number-box-disabled-border-color: #6c6ce0;
  --header-color: #0d0da5;
  --active-button-color: #0d0da5;
  --hover-button-color: #4747d1;
  --mode-button-selected: #0d0da5;
  --min-cell-width: 20px;
  --min-button-width: 20px;
  --background-gradient-top: #a7b6dd;
  --background-gradient-bottom: #d5d5fb;
  --select-td-background: #cecef8;
  --submit-button-background: #6b6bff;
  --submit-button-border: #3b3b91;
  --box-shadow-distance: 2px;
  --box-gradient: linear-gradient(#e4e4f6, #cecef8);
  --box-gradient-reverse: linear-gradient(#cecef8, #e4e4f6);
  --matrix-cell-row-sums-background-color: #b1f1b4;
  --row-sums-box-gradient: red;
  --rows-sums-font-color: #000;
  --matrix-cell-alternating-row-sums-background-color: #f0db8a;
  --matrix-cell-zero-ev-background-color: #debdba;
  --matrix-cell-one-ev-background-color: #f1a779;
  --alternating-row-sums-box-gradient: red;
  --alternating-rows-sums-font-color: #000;
  --matrix-cell-antidiagonal-sums-background-color: #9fd062;
  --matrix-cell-alternating-antidiagonal-sums-background-color: #89dcca;
  --mini-number-box-height: 33px;
  --mini-number-box-width: 33px;
  --mini-number-box-background-color: #f1f1f4;
  --mini-number-box-font-color: #0f0fbd;
  --mini-number-box-border-color: #5a5af2;
  --mini-number-box-border-radius: 4px;
  --user-history-cell-background: #e4e4fb;
  --star-sequence-font-color: #060679;
  font-family: Lato, sans-serif;
}

body, html {
  height: 100%;
}

.nonexistent {
  color: #5a5af2;
}

button {
  cursor: pointer;
  text-align: left;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
}

header {
  height: var(--header-height);
  border-bottom: 1px solid var(--color-gray-700);
  color: var(--color-gray-300);
  display: flex;
}

header .side {
  width: var(--header-height);
  place-content: center;
  display: grid;
}

h1 {
  font-size: 2rem;
  line-height: var(--header-height);
  text-align: center;
  flex: 1;
}

@media (max-width: 25rem) {
  h1 {
    font-size: 1.25rem;
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

/*# sourceMappingURL=index.652f91ea.css.map */
